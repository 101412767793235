.border-dashed {
    border-style: dashed;
}

.swal2-styled.swal2-cancel {
    color: black;
}

.text-bg-green {
    background-color: #456a5f;
}

.btn-form-control {
    width: 3.05rem;
    height: 3.05rem;
}

.status-circle {
    display: flex;
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
}

.codex-editor .ce-toolbar__actions {
    opacity: 1 !important;
}

.codex-editor .ce-toolbar {
    display: block !important;
}
